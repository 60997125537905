*{
  --background-color: #535353;
  --overlay: #60788e;
  font-family: 'Courier New', Courier, monospace;
}
@font-face{
  font-family: 'NorseBold';
  src: url('./assets/fonts/Norsebold.otf') format('opentype')
}


h1{
  font-family: 'NorseBold';
  color: white;
  font-size: 3em;
  text-shadow: 2px 3px #558abb67;
}

h2{
  font-family: 'NorseBold';
  color: white;
  font-size: 2em;
  text-shadow: 2px 3px #558abb67;
  letter-spacing: 1px;
}
p {
  color: white;
  font-size: 1.2em;
}

a{
  text-decoration: none;
  color: white;
}


header > nav > a:hover{
  font-weight: 600;
  font-size: 17px;
}

header > nav > a:active{
  font-weight: 600;
  font-size: 16px;
  color: #558abb67;
}

.--space-between{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.--flex-end{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

body{
  background-color: var(--background-color);
  margin: 10px 8vw 0px 8vw;
}

.gallery{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, 15vw);
  gap: 1vw;
  margin-bottom: 10vh;
}

.gallery > img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.gallery > .image_1{
  display: none;
}

.gallery > .image_2 {
  grid-column: 1/2;
  grid-row: 3/5;
}
.gallery > .image_3 {
  grid-column: 2/3;
  grid-row: 2/4;
}
.gallery > .image_4 {
  grid-column: 2/4;
  grid-row: 4/5;
}
.gallery > .image_5 {
  grid-column: 3/4;
  grid-row: 1/3;
}
.gallery > .image_6 {
  grid-column: 3/4;
  grid-row: 3/4;
}

/* .gallery > .hero {
  grid-column: 1/3;
  grid-row: 1/2;
  align-self:last baseline;
}

.gallery > .social-links {
  grid-column: 1/2;
  grid-row: 2/3;
} */

header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

header > nav {
  display: flex;
  gap: 1vw;
}

.chris{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.page-container{
  display: flex;
}

.sliding-nav {
  height: 100%; /* 100% Full-height */
  width: 0vw; /* 0 width - change this with JavaScript */
  position: fixed; /* Stay in place */
  z-index: 1; /* Stay on top */
  top: 0; /* Stay at the top */
  right: 0;
  background-color: #60788e; /* Black*/
  overflow-x: hidden; /* Disable horizontal scroll */
  padding-top: 60px; /* Place content 60px from the top */
  transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
}

.hamburger{
  z-index:2;
}

.sliding-nav > nav{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: 20vw 10vw 0 0;
  font-size: 2rem;
  gap: 2vh;
}

/* 750 */

@media (min-width: 750px) {
  .gallery{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(4, 15vw);
    gap: 1vw;
    margin-bottom: 10vh;
  }
  .gallery > .hero {
    grid-column: 1/3;
    grid-row: 1/2;
    align-self:last baseline;
  }
  
  .gallery > .social-links {
    grid-column: 1/2;
    grid-row: 2/3;
  }
  .gallery > .image_1 {
    display: block;
    grid-column: 1/2;
    grid-row: 4/5;
  }
  .gallery > .image_2 {
    grid-column: 2/3;
    grid-row: 3/5;
  }
  .gallery > .image_3 {
    grid-column: 3/4;
    grid-row: 2/4;
  }
  .gallery > .image_4 {
    grid-column: 3/5;
    grid-row: 4/5;
  }
  .gallery > .image_5 {
    grid-column: 4/5;
    grid-row: 1/3;
  }
  .gallery > .image_6 {
    grid-column: 4/5;
    grid-row: 3/4;
  }
}

.main{
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}